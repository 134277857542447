export const navigationRoutes = {
  root: {
    name: 'Admin',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'autostrada',
      displayName: 'Автострада (old)',
      disabled: true,
      meta: {
        iconClass: 'fa fa-road',
      },
      children: [
        {
          name: 'autostrada-menu',
          displayName: 'Menu',
        },
        {
          name: 'autostrada-pages',
          displayName: 'Pages',
          disabled: true,
          children: [
            {
              name: 'autostrada-pages-home',
              displayName: 'Home',
            },
            {
              name: 'autostrada-pages-about',
              displayName: 'About',
            },
            {
              name: 'autostrada-pages-technic',
              displayName: 'Technic',
            },
            {
              name: 'autostrada-pages-service',
              displayName: 'Service',
            },
            {
              name: 'autostrada-pages-object',
              displayName: 'Object',
            },
            {
              name: 'autostrada-pages-career',
              displayName: 'Career',
            },
            {
              name: 'autostrada-pages-contacts',
              displayName: 'Contacts',
            },
            {
              name: 'autostrada-pages-news',
              displayName: 'News',
            },
            {
              name: 'autostrada-pages-error',
              displayName: 'Error',
            },
          ],
        },
        {
          name: 'autostrada-news',
          displayName: 'News',
        },
        {
          name: 'autostrada-widgets',
          disabled: true,
          displayName: 'Widgets',
          children: [
            {
              name: 'autostrada-widgets-slider',
              displayName: 'Slider',
            },
            {
              name: 'autostrada-widgets-partner',
              displayName: 'Partner',
            },
            {
              name: 'autostrada-widgets-service',
              displayName: 'Service',
            },
            {
              name: 'autostrada-widgets-contacts',
              displayName: 'Contacts',
            },
            {
              name: 'autostrada-widgets-plants',
              displayName: 'Plants',
            },
            {
              name: 'autostrada-widgets-achievements',
              displayName: 'Achievements',
            },
            {
              name: 'autostrada-widgets-geography',
              displayName: 'Geography',
            },
            {
              name: 'autostrada-widgets-team',
              displayName: 'Team',
            },
            {
              name: 'autostrada-widgets-social',
              displayName: 'Social responsibility',
            },
            {
              name: 'autostrada-widgets-departments',
              displayName: 'Departments',
            },
            {
              name: 'autostrada-widgets-vacancy',
              displayName: 'Vacancy',
            },
            {
              name: 'autostrada-widgets-road',
              displayName: 'Road',
            },
            {
              name: 'autostrada-widgets-advantage',
              displayName: 'Advantage',
            },
            {
              name: 'autostrada-widgets-machinery',
              displayName: 'Machinery',
            },
            {
              name: 'autostrada-widgets-region',
              displayName: 'Regions',
            },
            {
              name: 'autostrada-widgets-object-type',
              displayName: 'Object type',
            },
            {
              name: 'autostrada-widgets-object-class',
              displayName: 'Object class',
            },
            {
              name: 'autostrada-widgets-objects',
              displayName: 'Objects',
            },
            {
              name: 'autostrada-widgets-aw',
              displayName: 'Additional Work',
            },
            {
              name: 'autostrada-widgets-quality',
              displayName: 'Quality',
            },
          ],
        },
        {
          name: 'autostrada-forms',
          displayName: 'Forms',
          children: [
            {
              name: 'autostrada-forms-career',
              displayName: 'Career',
            },
            {
              name: 'autostrada-forms-feedback',
              displayName: 'Feedback',
            },
          ],
        },
        {
          name: 'autostrada-request',
          displayName: 'Request',
        },
        {
          name: 'autostrada-settings',
          displayName: 'Settings',
          disabled: true,
          children: [
            {
              name: 'autostrada-settings-mailer',
              displayName: 'Mailer',
            },
            {
              name: 'autostrada-settings-config',
              displayName: 'Config',
            },
            {
              name: 'autostrada-settings-translations',
              displayName: 'Translations',
            },
            {
              name: 'autostrada-settings-languages',
              displayName: 'Languages',
            },
          ],
        },
        {
          name: 'autostrada-seo',
          displayName: 'Seo',
        },
        {
          name: 'autostrada-letters',
          displayName: 'Letters',
        },
      ],
    },
    {
      name: '1lk',
      displayName: '1lk',
      disabled: true,
      meta: {
        iconClass: 'glyphicon glyphicon-italic',
      },
      children: [
        {
          name: 'Admin',
          displayName: 'Form',
          disabled: true,
          children: [
            {
              name: '1lk-form-configs',
              displayName: 'Configs',
            },
            {
              name: '1lk-form-submits',
              displayName: 'Form Submits',
            },
          ],
        },
        {
          name: 'Admin',
          displayName: 'Home page',
          disabled: true,
          children: [
            {
              name: '1lk-home-texts',
              displayName: 'Texts',
            },
            {
              name: '1lk-home-slider',
              displayName: 'Slider',
            },
            {
              name: '1lk-home-partners',
              displayName: 'Partners blocks',
            },
            {
              name: '1lk-home-pride',
              displayName: 'Our pride blocks',
            },
          ],
        },
        {
          name: 'Admin',
          displayName: 'About company',
          disabled: true,
          children: [
            {
              name: '1lk-about-texts',
              displayName: 'Texts',
            },
            {
              name: '1lk-about-advantage',
              displayName: 'Advantage',
            },
            {
              name: '1lk-about-history',
              displayName: 'History',
            },
            {
              name: '1lk-about-association',
              displayName: 'Associations',
            },
            {
              name: '1lk-about-team',
              displayName: 'Team',
            },
          ],
        },
        {
          name: 'Admin',
          displayName: 'Partnership',
          disabled: true,
          children: [
            {
              name: '1lk-partnership-text',
              displayName: 'Texts',
            },
            {
              name: '1lk-partnership',
              displayName: 'Work with us blocks',
            },
          ],
        },
        {
          name: '1lk-contacts',
          displayName: 'Contacts',
        },
        {
          name: 'Admin',
          displayName: 'Services',
          disabled: true,
          children: [
            {
              name: '1lk-service-text',
              displayName: 'Texts',
            },
            {
              name: '1lk-service',
              displayName: 'Service items',
            },
          ],
        },
        {
          name: 'Admin',
          displayName: 'Articles',
          disabled: true,
          children: [
            {
              name: '1lk-article-text',
              displayName: 'Texts',
            },
            {
              name: '1lk-article',
              displayName: 'Article items',
            },
          ],
        },
        {
          name: 'Admin',
          displayName: 'Menu',
          disabled: true,
          children: [
            {
              name: '1lk-menu-items',
              displayName: 'Menu Items',
            },
            {
              name: '1lk-menu-socials',
              displayName: 'Social links',
            },
            {
              name: '1lk-menu-config',
              displayName: 'Menu configs',
            },
          ],
        },
        {
          name: 'Admin',
          displayName: 'Settings',
          disabled: true,
          children: [
            {
              name: '1lk-settings-mailer',
              displayName: 'Mailer',
            },
            {
              name: '1lk-settings-config',
              displayName: 'Configurations',
            },
            {
              name: '1lk-settings-translations',
              displayName: 'Translations',
            },
            {
              name: '1lk-settings-languages',
              displayName: 'Languages',
            },
          ],
        },
        {
          name: 'Admin',
          displayName: 'Seo',
          disabled: true,
          children: [
            {
              name: '1lk-robots',
              displayName: 'Robots.txt',
            },
          ],
        },
        {
          name: '1lk-letters',
          displayName: 'Letters',
        },
      ],
    },
    {
      name: 'Admin',
      displayName: 'Kurs Agro',
      disabled: true,
      meta: {
        iconClass: 'glyphicon glyphicon-tree-deciduous',
      },
      children: [
        {
          name: 'Admin',
          displayName: 'Pages',
          disabled: true,
          children: [
            {
              name: 'agro-home',
              displayName: 'ГОЛОВНА | Page',
            },
            {
              name: 'agro-slider',
              displayName: 'ГОЛОВНА | Slider',
            },
            {
              name: 'agro-about-us',
              displayName: 'ПРО НАС | Page',
            },
            {
              name: 'agro-activity-page',
              displayName: 'Activity | Page',
            },
            {
              name: 'agro-activity-products',
              displayName: 'Activity | Products',
            },
            {
              name: 'agro-activity-achievements',
              displayName: 'Activity | Achievements',
            },
            {
              name: 'agro-activity-testimonials',
              displayName: 'Activity | Testimonials',
            },
            {
              name: 'agro-responsibility-page',
              displayName: 'Responsibility | Page',
            },
            {
              name: 'agro-responsibility-items',
              displayName: 'Responsibility | Items',
            },
            {
              name: 'agro-factors-advantages',
              displayName: 'Factors & Advantages',
            },
            {
              name: 'agro-blog-page',
              displayName: 'Blog | Page',
            },
            {
              name: 'agro-blog-items',
              displayName: 'Blog | Posts',
            },
            {
              name: 'agro-gallery-page',
              displayName: 'Gallery | Page',
            },
            {
              name: 'agro-gallery-categories',
              displayName: 'Gallery | Categories',
            },
            {
              name: 'agro-gallery-items',
              displayName: 'Gallery | Items',
            },
            {
              name: 'agro-media-page',
              displayName: 'МЕДІА',
            },
            {
              name: 'agro-career-page',
              displayName: 'КАР\'ЄРА',
            },
            {
              name: 'agro-contacts-page',
              displayName: 'Contacts',
            },
            {
              name: 'agro-404-page',
              displayName: '404',
            },
          ],
        },
        {
          name: 'Admin',
          displayName: 'Blocks',
          disabled: true,
          children: [
            {
              name: 'agro-blocks-factors',
              displayName: 'Factors',
            },
            {
              name: 'agro-blocks-about',
              displayName: 'About',
            },
            {
              name: 'agro-blocks-mission',
              displayName: 'Mission',
            },
            {
              name: 'agro-blocks-testimonials',
              displayName: 'Testimonials',
            },
          ],
        },
        {
          name: 'agro-team-items',
          displayName: 'Team',
        },
        {
          name: 'agro-partners-items',
          displayName: 'Partners',
        },
        {
          name: 'Admin',
          displayName: 'Requests',
          disabled: true,
          children: [
            {
              name: 'agro-vacancy',
              displayName: 'Vacancy Requests',
            },
            {
              name: 'agro-contact',
              displayName: 'Contact Requests',
            },
            {
              name: 'agro-subscribe',
              displayName: 'Subscribe Requests',
            },
          ],
        },
        {
          name: 'Admin',
          displayName: 'Settings',
          disabled: true,
          children: [
            {
              name: 'agro-settings-mailer',
              displayName: 'Mailer',
            },
            {
              name: 'agro-settings-config',
              displayName: 'Configurations',
            },
            {
              name: 'agro-settings-translations',
              displayName: 'Translations',
            },
            {
              name: 'agro-settings-languages',
              displayName: 'Languages',
            },
          ],
        },
        {
          name: 'Admin',
          displayName: 'SEO',
          disabled: true,
          children: [
            {
              name: 'agro-seo-robots',
              displayName: 'Robots.txt',
            },
            {
              name: 'agro-seo-redirects',
              displayName: 'Redirects',
            },
          ],
        },
      ],
    },
    {
      name: 'prylukyagro',
      displayName: 'Прилуки Агро',
      disabled: true,
      meta: {
        iconClass: 'fa fa-road',
      },
      children: [
        {
          name: 'prylukyagro-menu',
          displayName: 'Menu',
        },
        {
          name: 'prylukyagro-pages',
          displayName: 'Pages',
          disabled: true,
          children: [
            {
              name: 'prylukyagro-pages-home',
              displayName: 'Home',
            },
            {
              name: 'prylukyagro-pages-about',
              displayName: 'About',
            },
            // {
            //   name: 'prylukyagro-pages-technic',
            //   displayName: 'Technic',
            // },
            {
              name: 'prylukyagro-pages-service',
              displayName: 'Service',
            },
            {
              name: 'prylukyagro-pages-benefits',
              displayName: 'Benefits',
            },
            // {
            //   name: 'prylukyagro-pages-object',
            //   displayName: 'Object',
            // },
            {
              name: 'prylukyagro-pages-career',
              displayName: 'Career',
            },
            {
              name: 'prylukyagro-pages-contacts',
              displayName: 'Contacts',
            },
            // {
            //   name: 'prylukyagro-pages-news',
            //   displayName: 'News',
            // },
            // {
            //   name: 'prylukyagro-pages-error',
            //   displayName: 'Error',
            // },
            {
              name: 'prylukyagro-pages-values',
              displayName: 'Values',
            },
          ],
        },
        // {
        //   name: 'prylukyagro-news',
        //   displayName: 'News',
        // },
        {
          name: 'prylukyagro-widgets',
          disabled: true,
          displayName: 'Widgets',
          children: [
            // {
            //   name: 'prylukyagro-widgets-slider',
            //   displayName: 'Slider',
            // },
            {
              name: 'prylukyagro-widgets-partner',
              displayName: 'Partner',
            },
            {
              name: 'prylukyagro-widgets-service',
              displayName: 'Service',
            },
            {
              name: 'prylukyagro-widgets-statistic',
              displayName: 'Statistic',
            },
            {
              name: 'prylukyagro-widgets-benefits',
              displayName: 'Benefits',
            },
            {
              name: 'prylukyagro-widgets-values',
              displayName: 'Values',
            },
            {
              name: 'prylukyagro-widgets-contacts',
              displayName: 'Contacts',
            },
            // {
            //   name: 'prylukyagro-widgets-plants',
            //   displayName: 'Plants',
            // },
            // {
            //   name: 'prylukyagro-widgets-achievements',
            //   displayName: 'Achievements',
            // },
            // {
            //   name: 'prylukyagro-widgets-geography',
            //   displayName: 'Geography',
            // },
            // {
            //   name: 'prylukyagro-widgets-team',
            //   displayName: 'Team',
            // },
            // {
            //   name: 'prylukyagro-widgets-social',
            //   displayName: 'Social responsibility',
            // },
            // {
            //   name: 'prylukyagro-widgets-departments',
            //   displayName: 'Departments',
            // },
            // {
            //   name: 'prylukyagro-widgets-vacancy',
            //   displayName: 'Vacancy',
            // },
            // {
            //   name: 'prylukyagro-widgets-advantage',
            //   displayName: 'Advantage',
            // },
            // {
            //   name: 'prylukyagro-widgets-machinery',
            //   displayName: 'Machinery',
            // },
            // {
            //   name: 'prylukyagro-widgets-region',
            //   displayName: 'Regions',
            // },
            // {
            //   name: 'prylukyagro-widgets-object-type',
            //   displayName: 'Object type',
            // },
            // {
            //   name: 'prylukyagro-widgets-object-class',
            //   displayName: 'Object class',
            // },
            // {
            //   name: 'prylukyagro-widgets-objects',
            //   displayName: 'Objects',
            // },
            // {
            //   name: 'prylukyagro-widgets-aw',
            //   displayName: 'Additional Work',
            // },
            // {
            //   name: 'prylukyagro-widgets-quality',
            //   displayName: 'Quality',
            // },
          ],
        },
        {
          name: 'prylukyagro-forms',
          displayName: 'Forms',
          // children: [
          //   {
          //     name: 'prylukyagro-forms-career',
          //     displayName: 'Career',
          //   },
          //   {
          //     name: 'prylukyagro-forms-feedback',
          //     displayName: 'Feedback',
          //   },
          // ],
        },
        // {
        //   name: 'prylukyagro-request',
        //   displayName: 'Request',
        // },
        {
          name: 'prylukyagro-settings',
          displayName: 'Settings',
          // disabled: true,
          // children: [
          //   {
          //     name: 'prylukyagro-settings-mailer',
          //     displayName: 'Mailer',
          //   },
          //   {
          //     name: 'prylukyagro-settings-config',
          //     displayName: 'Config',
          //   },
          //   {
          //     name: 'prylukyagro-settings-translations',
          //     displayName: 'Translations',
          //   },
          //   {
          //     name: 'prylukyagro-settings-languages',
          //     displayName: 'Languages',
          //   },
          // ],
        },
        // {
        //   name: 'prylukyagro-seo',
        //   displayName: 'Seo',
        // },
        // {
        //   name: 'prylukyagro-letters',
        //   displayName: 'Letters',
        // },
      ],
    },
    // {
    //   name: 'ms-capital',
    //   displayName: 'MS Capital (old)',
    //   disabled: true,
    //   meta: {
    //     iconClass: 'fa fa-road',
    //   },
    //   children: [
    //     {
    //       name: 'ms-capital-menu',
    //       displayName: 'Menu',
    //     },
    //     {
    //       name: 'ms-capital-news',
    //       displayName: 'News',
    //     },
    //     {
    //       name: 'ms-capital-pages',
    //       disabled: true,
    //       displayName: 'Pages',
    //       children: [
    //         {
    //           name: 'ms-capital-home',
    //           displayName: 'Home',
    //         },
    //         {
    //           name: 'ms-capital-page-about',
    //           displayName: 'About',
    //         },
    //         {
    //           name: 'ms-capital-page-development',
    //           displayName: 'Development',
    //         },
    //         {
    //           name: 'ms-capital-companies',
    //           displayName: 'Companies',
    //         },
    //         {
    //           name: 'ms-capital-page-ceo',
    //           displayName: 'Ceo',
    //         },
    //         {
    //           name: 'ms-capital-page-contacts',
    //           displayName: 'Contacts',
    //         },
    //       ],
    //     },
    //     {
    //       name: 'ms-capital-widgets',
    //       disabled: true,
    //       displayName: 'Widgets',
    //       children: [
    //         {
    //           name: 'ms-capital-widgets-value',
    //           displayName: 'Values',
    //         },
    //       ],
    //     },
    //     {
    //       name: 'mscapital-forms',
    //       displayName: 'Forms',
    //     },
    //   ],
    // },
    {
      name: 'MS',
      displayName: 'MS Capital',
      disabled: true,
      meta: {
        iconClass: 'fa fa-building',
      },
      children: [
        {
          name: 'ms-menu',
          displayName: 'Menu',
        },
        {
          name: 'ms-news',
          displayName: 'News',
        },
        {
          name: 'ms-pages',
          disabled: true,
          displayName: 'Pages',
          children: [
            {
              name: 'ms-page-home',
              displayName: 'Home',
            },
            {
              name: 'ms-page-about',
              displayName: 'About',
            },
            {
              name: 'ms-page-development',
              displayName: 'Development',
            },
            {
              name: 'ms-companies',
              displayName: 'Companies',
            },
            {
              name: 'ms-page-ceo',
              displayName: 'Ceo',
            },
            {
              name: 'ms-page-contacts',
              displayName: 'Contacts',
            },
          ],
        },
        {
          name: 'ms-widgets',
          disabled: true,
          displayName: 'Widgets',
          children: [
            {
              name: 'ms-widgets-value',
              displayName: 'Values',
            },
          ],
        },
        // {
        //   name: 'ms-forms',
        //   displayName: 'Forms',
        // },
      ],
    },
    {
      name: 'admin',
      displayName: 'BGK',
      disabled: true,
      meta: {
        iconClass: 'fa fa-industry',
      },
      children: [
        {
          name: 'bgk-menu',
          displayName: 'Menu',
        },
        {
          name: 'bgk-pages',
          disabled: true,
          displayName: 'Pages',
          children: [
            {
              name: 'bgk-page-home',
              displayName: 'Home',
            },
            {
              name: 'bgk-page-about',
              displayName: 'About',
            },
            {
              name: 'bgk-page-advantages',
              displayName: 'Advantages',
            },
            {
              name: 'bgk-page-history',
              displayName: 'History',
            },
            {
              name: 'bgk-page-production',
              displayName: 'Production',
            },
            {
              name: 'bgk-page-report',
              displayName: 'Reports',
            },
            {
              name: 'bgk-page-contacts',
              displayName: 'Contacts',
            },
          ],
        },
        {
          name: 'bgk-widgets',
          disabled: true,
          displayName: 'Widgets',
          children: [
            {
              name: 'bgk-widgets-stats',
              displayName: 'Statistic',
            },
          ],
        },
        {
          name: 'mscapital-forms',
          displayName: 'Forms',
        },
      ],
    },
    {
      name: 'Admin',
      displayName: 'Shkil',
      disabled: true,
      meta: {
        iconClass: 'fa fa-user',
      },
      children: [
        {
          name: 'Admin',
          displayName: 'Pages',
          disabled: true,
          children: [
            {
              name: 'shkil-pages-header',
              displayName: 'Header/Footer',
            },
            {
              name: 'shkil-pages-menu',
              displayName: 'Menu',
            },
            {
              name: 'shkil-pages-home',
              displayName: 'Home',
            },
            {
              name: 'shkil-pages-about',
              displayName: 'About',
            },
            {
              name: 'shkil-pages-contact',
              displayName: 'Contact',
            },
            {
              name: 'shkil-pages-founder',
              displayName: 'Founder',
            },
            {
              name: 'shkil-pages-404',
              displayName: 'Not found page',
            },
          ],
        },
        {
          name: 'Admin',
          displayName: 'Activity',
          disabled: true,
          children: [
            {
              name: 'shkil-activity-categories',
              displayName: 'Category',
            },
            {
              name: 'shkil-activity-items',
              displayName: 'Items',
            },
          ],
        },
        {
          name: 'Admin',
          displayName: 'Forms',
          disabled: true,
          children: [
            {
              name: 'shkil-forms-config',
              displayName: 'Forms settings',
            },
            {
              name: 'shkil-contact',
              displayName: 'Requests',
            },
          ],
        },

        {
          name: 'Admin',
          displayName: 'Settings',
          disabled: true,
          children: [
            {
              name: 'shkil-settings-mailer',
              displayName: 'Mailer',
            },
            {
              name: 'shkil-settings-config',
              displayName: 'Configurations',
            },
            {
              name: 'shkil-settings-translations',
              displayName: 'Translations',
            },
            {
              name: 'shkil-settings-languages',
              displayName: 'Languages',
            },
          ],
        },
        {
          name: 'Admin',
          displayName: 'SEO',
          disabled: true,
          children: [
            {
              name: 'shkil-seo-robots',
              displayName: 'Robots.txt',
            },
          ],
        },
        {
          name: 'shkil-letters',
          displayName: 'Letters',
        },
      ],
    },
    {
      name: 'Admin',
      displayName: 'Інше',
      children: [
        {
          name: 'change-password',
          displayName: 'Змінити пароль',
        },
      ],
    },
  ],
}
