<template>
  <aside
    class="app-sidebar"
    :class="computedClass"
    :style="computedStyle"
  >
    <ul class="app-sidebar__menu">
      <template v-for="(site, siteKey) in items">
        <app-sidebar-link-group
          :key="siteKey"
          :minimized="minimized"
          :icon="site.meta && site.meta.iconClass"
          :title="$t(site.displayName)"
          :children="site.children"
          :active-by-default="hasActiveByDefault(site)"
        >
          <template v-for="(item, itemKey) in site.children">
            <app-sidebar-link-group
              :key="itemKey"
              :minimized="minimized"
              v-if="item.children"
              :title="$t(item.displayName)"
              :children="item.children"
              :active-by-default="hasActiveByDefault(item)"
            >
              <app-sidebar-link
                v-for="(subItem, subItemKey) in item.children"
                :key="subItemKey"
                :to="{ name: subItem.name }"
                :title="$t(subItem.displayName)"
              />
            </app-sidebar-link-group>
            <app-sidebar-link
              :key="itemKey"
              v-else
              :to="{ name: item.name }"
              :title="$t(item.displayName)"
            />
          </template>
        </app-sidebar-link-group>
      </template>
    </ul>
  </aside>
</template>

<script>
import { navigationRoutes } from './NavigationRoutes'
import AppSidebarLink from './components/AppSidebarLink'
import AppSidebarLinkGroup from './components/AppSidebarLinkGroup'
import { ColorThemeMixin } from '../../../services/vuestic-ui'

export default {
  name: 'app-sidebar',
  inject: ['contextConfig'],
  components: {
    AppSidebarLink,
    AppSidebarLinkGroup,
  },
  mixins: [ColorThemeMixin],
  props: {
    minimized: {
      type: Boolean,
      required: true,
    },
    color: {
      type: String,
      default: 'secondary',
    },
  },
  data () {
    return {
      items: navigationRoutes.routes,
    }
  },
  computed: {
    computedClass () {
      return {
        'app-sidebar--minimized': this.minimized,
      }
    },
    computedStyle () {
      return {
        backgroundColor: this.contextConfig.invertedColor ? 'white' : this.colorComputed,
      }
    },
  },
  methods: {
    hasActiveByDefault (item) {
      return item.children.some(child => child.name === this.$route.name)
    },
  },
}

</script>

<style lang="scss">
.app-sidebar {
  overflow: auto;
  display: flex;
  max-height: 100%;
  flex: 0 0 16rem;

  @include media-breakpoint-down(sm) {
    flex: 0 0 100%;
  }

  &--minimized {
    flex: 0 0 3.25rem;
  }

  &__menu {
    margin-bottom: 0;
    padding-top: 2.5625rem;
    padding-bottom: 2.5rem;
    list-style: none;
    padding-left: 0;
    width: 100%;
  }
}

.app-sidebar-link__item-icon-right {
  top: 50%;
  transform: translateY(-50%);
}
</style>
