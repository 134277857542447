import Vue from 'vue'
import Router from 'vue-router'
import AuthLayout from '../components/auth/AuthLayout'
import AppLayout from '../components/admin/AppLayout'

Vue.use(Router)

const EmptyParentComponent = {
  template: '<router-view></router-view>',
}

export default new Router({
  mode: process.env.VUE_APP_ROUTER_MODE_HISTORY === 'true' ? 'history' : 'hash',
  routes: [
    {
      path: '*',
      redirect: { name: 'dashboard' },
    },
    {
      path: '/auth',
      component: AuthLayout,
      children: [
        {
          name: 'login',
          path: 'login',
          component: () => import('../components/auth/login/Login.vue'),
        },
        {
          name: 'signup',
          path: 'signup',
          component: () => import('../components/auth/signup/Signup.vue'),
        },
        {
          name: 'recover-password',
          path: 'recover-password',
          component: () => import('../components/auth/recover-password/RecoverPassword.vue'),
        },
        {
          path: '',
          redirect: { name: 'login' },
        },
      ],
    },
    {
      name: 'Admin',
      path: '/admin',
      component: AppLayout,
      children: [
        {
          name: 'dashboard',
          path: 'dashboard',
          component: () => import('../components/dashboard/Dashboard.vue'),
          default: true,
        },
        {
          path: 'change-password',
          name: 'change-password',
          component: () => import('../components/ChangePassword.vue'),
        },
        {
          name: 'autostrada',
          path: 'autostrada',
          component: EmptyParentComponent,
          children: [
            {
              name: 'autostrada-menu',
              path: 'autostrada-menu',
              component: () => import('../components/autostrada/menu/MenuList'),
            },
            {
              name: 'autostrada-menu-edit',
              path: 'autostrada-menu/:id?',
              component: () => import('../components/autostrada/menu/MenuForm'),
              props: true,
            },
            {
              name: 'autostrada-pages',
              path: 'autostrada-pages',
              component: EmptyParentComponent,
            },
            {
              name: 'autostrada-pages-home',
              path: 'autostrada-pages-home',
              component: () => import('../components/autostrada/pages/Home'),
            },
            {
              name: 'autostrada-pages-about',
              path: 'autostrada-pages-about',
              component: () => import('../components/autostrada/pages/About'),
            },
            {
              name: 'autostrada-pages-technic',
              path: 'autostrada-pages-technic',
              component: () => import('../components/autostrada/pages/Technic'),
            },
            {
              name: 'autostrada-pages-service',
              path: 'autostrada-pages-service',
              component: () => import('../components/autostrada/pages/Service'),
            },
            {
              name: 'autostrada-pages-object',
              path: 'autostrada-pages-object',
              component: () => import('../components/autostrada/pages/Object'),
            },
            {
              name: 'autostrada-pages-career',
              path: 'autostrada-pages-career',
              component: () => import('../components/autostrada/pages/Career'),
            },
            {
              name: 'autostrada-pages-contacts',
              path: 'autostrada-pages-contacts',
              component: () => import('../components/autostrada/pages/Contact'),
            },
            {
              name: 'autostrada-pages-news',
              path: 'autostrada-pages-news',
              component: () => import('../components/autostrada/pages/News'),
            },
            {
              name: 'autostrada-pages-error',
              path: 'autostrada-pages-error',
              component: () => import('../components/autostrada/pages/Error'),
            },
            {
              name: 'autostrada-news',
              path: 'autostrada-news',
              component: () => import('../components/autostrada/news/List'),
            },
            {
              name: 'autostrada-news-edit',
              path: 'autostrada-news-edit/:id?',
              component: () => import('../components/autostrada/news/Form'),
              props: true,
            },
            {
              name: 'autostrada-widgets',
              path: 'autostrada-widgets',
              disabled: true,
              component: EmptyParentComponent,
            },
            {
              name: 'autostrada-widgets-slider',
              path: 'autostrada-widgets-slider',
              component: () => import('../components/autostrada/widgets/slider/List'),
            },
            {
              name: 'autostrada-slider-edit',
              path: 'autostrada-slider-edit/:id?',
              component: () => import('../components/autostrada/widgets/slider/Form'),
              props: true,
            },
            {
              name: 'autostrada-widgets-partner',
              path: 'autostrada-widgets-partner',
              component: () => import('../components/autostrada/widgets/partner/List'),
            },
            {
              name: 'autostrada-partner-edit',
              path: 'autostrada-partner-edit/:id?',
              component: () => import('../components/autostrada/widgets/partner/Form'),
              props: true,
            },
            {
              name: 'autostrada-widgets-service',
              path: 'autostrada-widgets-service',
              component: () => import('../components/autostrada/widgets/service/List'),
            },
            {
              name: 'autostrada-service-edit',
              path: 'autostrada-service-edit/:id?',
              component: () => import('../components/autostrada/widgets/service/Form'),
              props: true,
            },
            {
              name: 'autostrada-widgets-contacts',
              path: 'autostrada-widgets-contacts',
              component: () => import('../components/autostrada/widgets/contacts/Form'),
            },
            {
              name: 'autostrada-widgets-plants',
              path: 'autostrada-widgets-plants',
              component: () => import('../components/autostrada/widgets/plants/List'),
            },
            {
              name: 'autostrada-plants-edit',
              path: 'autostrada-plants-edit/:id?',
              component: () => import('../components/autostrada/widgets/plants/Form'),
              props: true,
            },
            {
              name: 'autostrada-widgets-achievements',
              path: 'autostrada-widgets-achievements',
              component: () => import('../components/autostrada/widgets/achievement/List'),
            },
            {
              name: 'autostrada-achievements-edit',
              path: 'autostrada-achievements-edit/:id?',
              component: () => import('../components/autostrada/widgets/achievement/Form'),
              props: true,
            },
            {
              name: 'autostrada-widgets-geography',
              path: 'autostrada-geography',
              component: () => import('../components/autostrada/widgets/geography/Form'),
            },
            {
              name: 'autostrada-widgets-team',
              path: 'autostrada-widgets-team',
              component: () => import('../components/autostrada/widgets/team/List'),
            },
            {
              name: 'autostrada-team-edit',
              path: 'autostrada-team-edit/:id?',
              component: () => import('../components/autostrada/widgets/team/Form'),
              props: true,
            },
            {
              name: 'autostrada-widgets-social',
              path: 'autostrada-widgets-social',
              component: () => import('../components/autostrada/widgets/social/List'),
            },
            {
              name: 'autostrada-social-edit',
              path: 'autostrada-social-edit/:id?',
              component: () => import('../components/autostrada/widgets/social/Form'),
              props: true,
            },
            {
              name: 'autostrada-widgets-departments',
              path: 'autostrada-widgets-departments',
              component: () => import('../components/autostrada/widgets/department/List'),
            },
            {
              name: 'autostrada-departments-edit',
              path: 'autostrada-departments-edit/:id?',
              component: () => import('../components/autostrada/widgets/department/Form'),
              props: true,
            },
            {
              name: 'autostrada-widgets-vacancy',
              path: 'autostrada-widgets-vacancy',
              component: () => import('../components/autostrada/widgets/vacancy/List'),
            },
            {
              name: 'autostrada-vacancy-edit',
              path: 'autostrada-vacancy-edit/:id?',
              component: () => import('../components/autostrada/widgets/vacancy/Form'),
              props: true,
            },
            {
              name: 'autostrada-widgets-road',
              path: 'autostrada-widgets-road',
              component: () => import('../components/autostrada/widgets/road/List'),
            },
            {
              name: 'autostrada-road-edit',
              path: 'autostrada-road-edit/:id?',
              component: () => import('../components/autostrada/widgets/road/Form'),
              props: true,
            },
            {
              name: 'autostrada-widgets-advantage',
              path: 'autostrada-widgets-advantage',
              component: () => import('../components/autostrada/widgets/advantage/List'),
            },
            {
              name: 'autostrada-advantage-edit',
              path: 'autostrada-advantage-edit/:id?',
              component: () => import('../components/autostrada/widgets/advantage/Form'),
              props: true,
            },
            {
              name: 'autostrada-widgets-machinery',
              path: 'autostrada-widgets-machinery',
              component: () => import('../components/autostrada/widgets/machinery/List'),
            },
            {
              name: 'autostrada-machinery-edit',
              path: 'autostrada-machinery-edit/:id?',
              component: () => import('../components/autostrada/widgets/machinery/Form'),
              props: true,
            },
            {
              name: 'autostrada-widgets-region',
              path: 'autostrada-widgets-region',
              component: () => import('../components/autostrada/widgets/region/List'),
            },
            {
              name: 'autostrada-region-edit',
              path: 'autostrada-region-edit/:id?',
              component: () => import('../components/autostrada/widgets/region/Form'),
              props: true,
            },
            {
              name: 'autostrada-widgets-object-type',
              path: 'autostrada-widgets-object-type',
              component: () => import('../components/autostrada/widgets/object-type/List'),
            },
            {
              name: 'autostrada-object-type-edit',
              path: 'autostrada-object-type-edit/:id?',
              component: () => import('../components/autostrada/widgets/object-type/Form'),
              props: true,
            },
            {
              name: 'autostrada-widgets-object-class',
              path: 'autostrada-widgets-object-class',
              component: () => import('../components/autostrada/widgets/object-class/List'),
            },
            {
              name: 'autostrada-object-class-edit',
              path: 'autostrada-object-class-edit/:id?',
              component: () => import('../components/autostrada/widgets/object-class/Form'),
              props: true,
            },
            {
              name: 'autostrada-widgets-objects',
              path: 'autostrada-widgets-objects',
              component: () => import('../components/autostrada/widgets/objects/List'),
            },
            {
              name: 'autostrada-objects-edit',
              path: 'autostrada-objects-edit/:id?',
              component: () => import('../components/autostrada/widgets/objects/Form'),
              props: true,
            },
            {
              name: 'autostrada-widgets-aw',
              path: 'autostrada-widgets-aw',
              component: () => import('../components/autostrada/widgets/aw/List'),
            },
            {
              name: 'autostrada-aw-edit',
              path: 'autostrada-aw-edit/:id?',
              component: () => import('../components/autostrada/widgets/aw/Form'),
              props: true,
            },
            {
              name: 'autostrada-widgets-quality',
              path: 'autostrada-widgets-quality',
              component: () => import('../components/autostrada/widgets/quality/List'),
            },
            {
              name: 'autostrada-quality-edit',
              path: 'autostrada-quality-edit/:id?',
              component: () => import('../components/autostrada/widgets/quality/Form'),
              props: true,
            },
            {
              name: 'autostrada-forms',
              path: 'autostrada-forms',
              disabled: true,
              component: EmptyParentComponent,
            },
            {
              name: 'autostrada-forms-feedback',
              path: 'autostrada-forms-feedback',
              component: () => import('../components/autostrada/forms/Feedback'),
            },
            {
              name: 'autostrada-forms-career',
              path: 'autostrada-forms-career',
              component: () => import('../components/autostrada/forms/Career'),
            },
            {
              name: 'autostrada-request',
              path: 'autostrada-request',
              component: () => import('../components/autostrada/request/List'),
            },
            {
              name: 'autostrada-request-view',
              path: 'autostrada-request/:id',
              component: () => import('../components/autostrada/request/Item'),
              props: true,
            },
            {
              name: 'autostrada-settings',
              path: 'autostrada-settings',
              disabled: true,
              component: EmptyParentComponent,
            },
            {
              name: 'autostrada-settings-mailer',
              path: 'autostrada-settings-mailer',
              component: () => import('../components/autostrada/settings/mailer/List'),
            },
            {
              name: 'autostrada-settings-mailer-edit',
              path: 'autostrada-settings-mailer-edit/:id?',
              component: () => import('../components/autostrada/settings/mailer/Form'),
              props: true,
            },
            {
              name: 'autostrada-settings-config',
              path: 'autostrada-settings-config',
              component: () => import('../components/autostrada/settings/Config'),
            },
            {
              name: 'autostrada-settings-translations',
              path: 'autostrada-settings-translations',
              component: () => import('../components/autostrada/settings/translations/List'),
            },
            {
              name: 'autostrada-settings-translations-edit',
              path: 'autostrada-settings-translations-edit/:id?',
              component: () => import('../components/autostrada/settings/translations/Form'),
              props: true,
            },
            {
              name: 'autostrada-settings-languages',
              path: 'autostrada-settings-languages',
              component: () => import('../components/autostrada/settings/languages/List'),
            },
            {
              name: 'autostrada-settings-languages-edit',
              path: 'autostrada-settings-languages-edit/:id?',
              component: () => import('../components/autostrada/settings/languages/Form'),
              props: true,
            },
            {
              name: 'autostrada-seo',
              path: 'autostrada-seo',
              component: () => import('../components/autostrada/Seo'),
              props: true,
            },
            {
              name: 'autostrada-letters',
              path: 'autostrada-letters',
              component: () => import('../components/autostrada/letters/List'),
            },
            {
              name: 'autostrada-letters-edit',
              path: 'autostrada-letters-edit/:id',
              component: () => import('../components/autostrada/letters/Item'),
              props: true,
            },
          ],
        },
        {
          name: '1lk',
          path: '1lk',
          component: EmptyParentComponent,
          children: [
            {
              name: '1lk-form-configs',
              path: '1lk-form-configs',
              component: () => import('../components/1lk/forms/Configs'),
            },
            {
              name: '1lk-form-submits',
              path: '1lk-form-submits',
              component: () => import('../components/1lk/forms/Submits'),
            },
            {
              name: '1lk-form-submits-view',
              path: '1lk-form-submits-view/:id',
              props: true,
              component: () => import('../components/1lk/forms/Submit'),
            },
            {
              name: '1lk-home-texts',
              path: '1lk-home-texts',
              props: true,
              component: () => import('../components/1lk/home-page/Texts'),
            },
            {
              name: '1lk-home-slider',
              path: '1lk-home-slider',
              component: () => import('../components/1lk/home-page/Slider'),
            },
            {
              name: '1lk-home-slider-edit',
              path: '1lk-home-slider-edit/:id?',
              props: true,
              component: () => import('../components/1lk/home-page/SliderForm'),
            },
            {
              name: '1lk-home-partners',
              path: '1lk-home-partners',
              component: () => import('../components/1lk/home-page/PartnersBlock'),
            },
            {
              name: '1lk-home-partners-edit',
              path: '1lk-home-partners-edit/:id?',
              props: true,
              component: () => import('../components/1lk/home-page/PartnerForm'),
            },
            {
              name: '1lk-home-pride',
              path: '1lk-home-pride',
              component: () => import('../components/1lk/home-page/PrideBlocks'),
            },
            {
              name: '1lk-home-pride-edit',
              path: '1lk-home-pride-edit/:id?',
              props: true,
              component: () => import('../components/1lk/home-page/PrideForm'),
            },
            {
              name: '1lk-about-texts',
              path: '1lk-about-texts',
              props: true,
              component: () => import('../components/1lk/about-page/Texts'),
            },
            {
              name: '1lk-about-advantage',
              path: '1lk-about-advantage',
              component: () => import('../components/1lk/about-page/Advantages'),
            },
            {
              name: '1lk-about-advantage-edit',
              path: '1lk-about-advantage-edit/:id?',
              props: true,
              component: () => import('../components/1lk/about-page/Advantage'),
            },
            {
              name: '1lk-about-history',
              path: '1lk-about-history',
              component: () => import('../components/1lk/about-page/Histories'),
            },
            {
              name: '1lk-about-history-edit',
              path: '1lk-about-history-edit/:id?',
              props: true,
              component: () => import('../components/1lk/about-page/History'),
            },
            {
              name: '1lk-about-association',
              path: '1lk-about-association',
              component: () => import('../components/1lk/about-page/Associations'),
            },
            {
              name: '1lk-about-association-edit',
              path: '1lk-about-association-edit/:id?',
              props: true,
              component: () => import('../components/1lk/about-page/Association'),
            },
            {
              name: '1lk-about-team',
              path: '1lk-about-team',
              component: () => import('../components/1lk/about-page/Teams'),
            },
            {
              name: '1lk-about-team-edit',
              path: '1lk-about-team-edit/:id?',
              props: true,
              component: () => import('../components/1lk/about-page/Team'),
            },
            {
              name: '1lk-partnership',
              path: '1lk-partnership',
              component: () => import('../components/1lk/partnership/List'),
            },
            {
              name: '1lk-partnership-edit',
              path: '1lk-partnership-edit/:id?',
              props: true,
              component: () => import('../components/1lk/partnership/Form'),
            },
            {
              name: '1lk-partnership-text',
              path: '1lk-partnership-text',
              props: true,
              component: () => import('../components/1lk/partnership/Text'),
            },
            {
              name: '1lk-contacts',
              path: '1lk-contacts',
              props: true,
              component: () => import('../components/1lk/Contacts'),
            },
            {
              name: '1lk-service',
              path: '1lk-service',
              props: true,
              component: () => import('../components/1lk/services/List'),
            },
            {
              name: '1lk-service-edit',
              path: '1lk-service-edit/:id?',
              props: true,
              component: () => import('../components/1lk/services/Form'),
            },
            {
              name: '1lk-service-text',
              path: '1lk-service-text',
              component: () => import('../components/1lk/services/Text'),
            },
            {
              name: '1lk-article',
              path: '1lk-article',
              props: true,
              component: () => import('../components/1lk/articles/List'),
            },
            {
              name: '1lk-article-edit',
              path: '1lk-article-edit/:id?',
              props: true,
              component: () => import('../components/1lk/articles/Form'),
            },
            {
              name: '1lk-article-text',
              path: '1lk-article-text',
              component: () => import('../components/1lk/articles/Text'),
            },
            {
              name: '1lk-menu-items',
              path: '1lk-menu-items',
              component: () => import('../components/1lk/menu/Menus'),
            },
            {
              name: '1lk-menu-items-edit',
              path: '1lk-menu-items-edit/:id?',
              props: true,
              component: () => import('../components/1lk/menu/Menu'),
            },
            {
              name: '1lk-menu-socials',
              path: '1lk-menu-socials',
              component: () => import('../components/1lk/menu/Socials'),
            },
            {
              name: '1lk-menu-socials-edit',
              path: '1lk-menu-socials-edit/:id?',
              props: true,
              component: () => import('../components/1lk/menu/Social'),
            },
            {
              name: '1lk-menu-config',
              path: '1lk-menu-config',
              props: true,
              component: () => import('../components/1lk/menu/Configs'),
            },
            {
              name: '1lk-settings-mailer',
              path: '1lk-settings-mailer',
              component: () => import('../components/1lk/settings/mailer/List'),
            },
            {
              name: '1lk-settings-mailer-edit',
              path: '1lk-settings-mailer-edit/:id?',
              props: true,
              component: () => import('../components/1lk/settings/mailer/Form'),
            },
            {
              name: '1lk-settings-config',
              path: '1lk-settings-config',
              component: () => import('../components/1lk/settings/Config'),
            },
            {
              name: '1lk-settings-translations',
              path: '1lk-settings-translations',
              component: () => import('../components/1lk/settings/translations/List'),
            },
            {
              name: '1lk-settings-translations-edit',
              path: '1lk-settings-translations-edit/:id?',
              props: true,
              component: () => import('../components/1lk/settings/translations/Form'),
            },
            {
              name: '1lk-settings-languages',
              path: '1lk-settings-languages',
              component: () => import('../components/1lk/settings/languages/List'),
            },
            {
              name: '1lk-settings-languages-edit',
              path: '1lk-settings-languages-edit/:id?',
              props: true,
              component: () => import('../components/1lk/settings/languages/Form'),
            },
            {
              name: '1lk-robots',
              path: '1lk-robots',
              component: () => import('../components/1lk/Robots'),
            },
            {
              name: '1lk-letters',
              path: '1lk-letters',
              component: () => import('../components/1lk/letters/List'),
            },
            {
              name: '1lk-letters-view',
              path: '1lk-letters-view/:id',
              props: true,
              component: () => import('../components/1lk/letters/Item'),
            },
          ],
        },
        {
          name: 'agro',
          path: 'agro',
          component: EmptyParentComponent,
          children: [
            {
              name: 'agro-home',
              path: 'agro-home',
              component: () => import('../components/agro/pages/home/Page.vue'),
            },
            {
              name: 'agro-slider',
              path: 'agro-slider',
              component: () => import('../components/agro/pages/home/Slider.vue'),
            },
            {
              name: 'agro-about-us',
              path: 'agro-about-us',
              component: () => import('../components/agro/pages/about-us/Page.vue'),
            },
            {
              name: 'agro-activity-page',
              path: 'agro-activity-page',
              component: () => import('../components/agro/pages/activity/Page.vue'),
            },
            {
              name: 'agro-activity-products',
              path: 'agro-activity-products',
              component: () => import('../components/agro/pages/activity/Products.vue'),
            },
            {
              name: 'agro-activity-products-edit',
              path: 'agro-activity-products-edit/:id?',
              props: true,
              component: () => import('../components/agro/pages/activity/Product.vue'),
            },
            {
              name: 'agro-activity-achievements',
              path: 'agro-activity-achievements',
              component: () => import('../components/agro/pages/activity/Achievements'),
            },
            {
              name: 'agro-activity-achievements-edit',
              path: 'agro-activity-achievements-edit/:id?',
              props: true,
              component: () => import('../components/agro/pages/activity/Achievement'),
            },
            {
              name: 'agro-activity-testimonials',
              path: 'agro-activity-testimonials',
              component: () => import('../components/agro/pages/activity/Testimonials'),
            },
            {
              name: 'agro-activity-testimonials-edit',
              path: 'agro-activity-testimonials-edit/:id?',
              props: true,
              component: () => import('../components/agro/pages/activity/Testimonial'),
            },
            {
              name: 'agro-responsibility-items',
              path: 'agro-responsibility-items',
              component: () => import('../components/agro/pages/responsibility/Items'),
            },
            {
              name: 'agro-responsibility-items-edit',
              path: 'agro-responsibility-items-edit/:id?',
              props: true,
              component: () => import('../components/agro/pages/responsibility/Item'),
            },
            {
              name: 'agro-responsibility-page',
              path: 'agro-responsibility-page',
              component: () => import('../components/agro/pages/responsibility/Page'),
            },
            {
              name: 'agro-factors-advantages',
              path: 'agro-factors-advantages',
              component: () => import('../components/agro/pages/factors-advantages/Page'),
            },
            {
              name: 'agro-blog-page',
              path: 'agro-blog-page',
              component: () => import('../components/agro/pages/blog/Page'),
            },
            {
              name: 'agro-blog-items',
              path: 'agro-blog-items',
              component: () => import('../components/agro/pages/blog/Items'),
            },
            {
              name: 'agro-blog-items-edit',
              path: 'agro-blog-items-edit/:id?',
              props: true,
              component: () => import('../components/agro/pages/blog/Item'),
            },
            {
              name: 'agro-gallery-page',
              path: 'agro-gallery-page',
              component: () => import('../components/agro/pages/gallery/Page'),
            },
            {
              name: 'agro-gallery-categories',
              path: 'agro-gallery-categories',
              component: () => import('../components/agro/pages/gallery/Categories'),
            },
            {
              name: 'agro-gallery-categories-edit',
              path: 'agro-gallery-categories-edit/:id?',
              props: true,
              component: () => import('../components/agro/pages/gallery/Category'),
            },
            {
              name: 'agro-gallery-items',
              path: 'agro-gallery-items',
              component: () => import('../components/agro/pages/gallery/Items'),
            },
            {
              name: 'agro-gallery-items-edit',
              path: 'agro-gallery-items-edit/:id?',
              props: true,
              component: () => import('../components/agro/pages/gallery/Item'),
            },
            {
              name: 'agro-media-page',
              path: 'agro-media-page',
              component: () => import('../components/agro/pages/media/Page'),
            },
            {
              name: 'agro-career-page',
              path: 'agro-career-page',
              component: () => import('../components/agro/pages/career/Page'),
            },
            {
              name: 'agro-contacts-page',
              path: 'agro-contacts-page',
              component: () => import('../components/agro/pages/contacts/Page'),
            },
            {
              name: 'agro-404-page',
              path: 'agro-404-page',
              component: () => import('../components/agro/pages/404/Page'),
            },
            {
              name: 'agro-blocks-factors',
              path: 'agro-blocks-factors',
              component: () => import('../components/agro/blocks/Factors'),
            },
            {
              name: 'agro-blocks-about',
              path: 'agro-blocks-about',
              component: () => import('../components/agro/blocks/About'),
            },
            {
              name: 'agro-blocks-mission',
              path: 'agro-blocks-mission',
              component: () => import('../components/agro/blocks/Mission'),
            },
            {
              name: 'agro-blocks-testimonials',
              path: 'agro-blocks-testimonials',
              component: () => import('../components/agro/blocks/Testimonials'),
            },
            {
              name: 'agro-team-items',
              path: 'agro-team-items',
              component: () => import('../components/agro/team/Items'),
            },
            {
              name: 'agro-team-items-edit',
              path: 'agro-team-items-edit/:id?',
              props: true,
              component: () => import('../components/agro/team/Item'),
            },
            {
              name: 'agro-partners-items',
              path: 'agro-partners-items',
              component: () => import('../components/agro/partners/Items'),
            },
            {
              name: 'agro-partners-items-edit',
              path: 'agro-partners-items-edit/:id?',
              props: true,
              component: () => import('../components/agro/partners/Item'),
            },
            {
              name: 'agro-vacancy',
              path: 'agro-vacancy',
              component: () => import('../components/agro/requests/vacancy/List'),
            },
            {
              name: 'agro-vacancy-view',
              path: 'agro-vacancy-view/:id?',
              props: true,
              component: () => import('../components/agro/requests/vacancy/Item'),
            },
            {
              name: 'agro-contact',
              path: 'agro-contact',
              component: () => import('../components/agro/requests/contact/List'),
            },
            {
              name: 'agro-contact-view',
              path: 'agro-contact-view/:id?',
              props: true,
              component: () => import('../components/agro/requests/contact/Item'),
            },
            {
              name: 'agro-subscribe',
              path: 'agro-subscribe',
              component: () => import('../components/agro/requests/subscribe/List'),
            },
            {
              name: 'agro-subscribe-view',
              path: 'agro-subscribe-view/:id?',
              props: true,
              component: () => import('../components/agro/requests/subscribe/Item'),
            },
            {
              name: 'agro-settings-mailer',
              path: 'agro-settings-mailer',
              component: () => import('../components/agro/settings/mailer/List'),
            },
            {
              name: 'agro-settings-mailer-edit',
              path: 'agro-settings-mailer-edit/:id?',
              props: true,
              component: () => import('../components/agro/settings/mailer/Form'),
            },
            {
              name: 'agro-settings-config',
              path: 'agro-settings-config',
              component: () => import('../components/agro/settings/Config'),
            },
            {
              name: 'agro-settings-translations',
              path: 'agro-settings-translations',
              component: () => import('../components/agro/settings/translations/List'),
            },
            {
              name: 'agro-settings-translations-edit',
              path: 'agro-settings-translations-edit/:id?',
              props: true,
              component: () => import('../components/agro/settings/translations/Form'),
            },
            {
              name: 'agro-settings-languages',
              path: 'agro-settings-languages',
              component: () => import('../components/agro/settings/languages/List'),
            },
            {
              name: 'agro-settings-languages-edit',
              path: 'agro-settings-languages-edit/:id?',
              props: true,
              component: () => import('../components/agro/settings/languages/Form'),
            },
            {
              name: 'agro-seo-robots',
              path: 'agro-seo-robots',
              component: () => import('../components/agro/seo/Robots'),
            },
            {
              name: 'agro-seo-redirects',
              path: 'agro-seo-redirects',
              component: () => import('../components/agro/seo/Redirects'),
            },
            {
              name: 'agro-seo-redirects-edit',
              path: 'agro-seo-redirects-edit/:id?',
              props: true,
              component: () => import('../components/agro/seo/Redirect'),
            },
          ],
        },
        {
          name: 'shkil',
          path: 'shkil',
          component: EmptyParentComponent,
          children: [
            {
              name: 'shkil-pages-header',
              path: 'shkil-pages-header',
              component: () => import('../components/shkil/pages/Header'),
            },
            {
              name: 'shkil-pages-menu',
              path: 'shkil-pages-menu',
              component: () => import('../components/shkil/pages/menu/Items'),
            },
            {
              name: 'shkil-pages-menu-edit',
              path: 'shkil-pages-menu-edit/:id?',
              props: true,
              component: () => import('../components/shkil/pages/menu/Item'),
            },
            {
              name: 'shkil-pages-home',
              path: 'shkil-pages-home',
              component: () => import('../components/shkil/pages/Home'),
            },
            {
              name: 'shkil-pages-about',
              path: 'shkil-pages-about',
              component: () => import('../components/shkil/pages/About'),
            },
            {
              name: 'shkil-pages-contact',
              path: 'shkil-pages-contact',
              component: () => import('../components/shkil/pages/Contact'),
            },
            {
              name: 'shkil-pages-founder',
              path: 'shkil-pages-founder',
              component: () => import('../components/shkil/pages/Founder'),
            },
            {
              name: 'shkil-pages-404',
              path: 'shkil-pages-404',
              component: () => import('../components/shkil/pages/404'),
            },
            {
              name: 'shkil-activity-categories',
              path: 'shkil-activity-categories',
              component: () => import('../components/shkil/activity/Categories'),
            },
            {
              name: 'shkil-activity-categories-edit',
              path: 'shkil-activity-categories-edit/:id?',
              props: true,
              component: () => import('../components/shkil/activity/Category'),
            },
            {
              name: 'shkil-activity-items',
              path: 'shkil-activity-items',
              component: () => import('../components/shkil/activity/Items'),
            },
            {
              name: 'shkil-activity-items-edit',
              path: 'shkil-activity-items-edit/:id?',
              props: true,
              component: () => import('../components/shkil/activity/Item'),
            },
            {
              name: 'shkil-forms-config',
              path: 'shkil-forms-config',
              component: () => import('../components/shkil/forms/Config'),
            },
            {
              name: 'shkil-contact',
              path: 'shkil-contact',
              component: () => import('../components/shkil/forms/Requests'),
            },
            {
              name: 'shkil-contact-view',
              path: 'shkil-contact-view/:id?',
              props: true,
              component: () => import('../components/shkil/forms/Request'),
            },
            {
              name: 'shkil-settings-mailer',
              path: 'shkil-settings-mailer',
              component: () => import('../components/shkil/settings/mailer/List'),
            },
            {
              name: 'shkil-settings-mailer-edit',
              path: 'shkil-settings-mailer-edit/:id?',
              props: true,
              component: () => import('../components/shkil/settings/mailer/Form'),
            },
            {
              name: 'shkil-settings-config',
              path: 'shkil-settings-config',
              component: () => import('../components/shkil/settings/Config'),
            },
            {
              name: 'shkil-settings-translations',
              path: 'shkil-settings-translations',
              component: () => import('../components/shkil/settings/translations/List'),
            },
            {
              name: 'shkil-settings-translations-edit',
              path: 'shkil-settings-translations-edit/:id?',
              props: true,
              component: () => import('../components/shkil/settings/translations/Form'),
            },
            {
              name: 'shkil-settings-languages',
              path: 'shkil-settings-languages',
              component: () => import('../components/shkil/settings/languages/List'),
            },
            {
              name: 'shkil-settings-languages-edit',
              path: 'shkil-settings-languages-edit/:id?',
              props: true,
              component: () => import('../components/shkil/settings/languages/Form'),
            },
            {
              name: 'shkil-seo-robots',
              path: 'shkil-seo-robots',
              component: () => import('../components/shkil/seo/Robots'),
            },
            {
              name: 'shkil-letters',
              path: 'shkil-letters',
              component: () => import('../components/shkil/letters/List'),
            },
            {
              name: 'shkil-letters-view',
              path: 'shkil-letters-view/:id',
              props: true,
              component: () => import('../components/shkil/letters/Item'),
            },
          ],
        },
        {
          name: 'ms-capital',
          path: 'ms-capital',
          component: EmptyParentComponent,
          children: [
            {
              name: 'ms-capital-menu',
              path: 'ms-capital-menu',
              component: () => import('../components/ms-capital/menu/MenuList'),
            },
            {
              name: 'ms-capital-menu-edit',
              path: 'ms-capital-menu-edit/:id?',
              component: () => import('../components/ms-capital/menu/MenuForm'),
              props: true,
            },
            {
              name: 'ms-capital-news',
              path: 'ms-capital-news',
              component: () => import('../components/ms-capital/news/List'),
            },
            {
              name: 'ms-capital-news-edit',
              path: 'ms-capital-news-edit/:id?',
              component: () => import('../components/ms-capital/news/Form'),
              props: true,
            },
            {
              name: 'ms-capital-widgets',
              path: 'ms-capital-widgets',
              disabled: true,
              component: EmptyParentComponent,
            },
            {
              name: 'ms-capital-widgets-value',
              path: 'ms-capital-widgets-value',
              component: () => import('../components/ms-capital/widgets/value/List'),
            },
            {
              name: 'ms-capital-widgets-value-edit',
              path: 'ms-capital-widgets-value-edit/:id?',
              component: () => import('../components/ms-capital/widgets/value/Form'),
              props: true,
            },
            {
              name: 'ms-capital-home',
              path: 'ms-capital-home',
              component: () => import('../components/ms-capital/pages/HomeList'),
            },
            {
              name: 'ms-capital-home-edit',
              path: 'ms-capital-home-edit/:id?',
              component: () => import('../components/ms-capital/pages/HomeForm'),
              props: true,
            },
            {
              name: 'ms-capital-companies',
              path: 'ms-capital-companies',
              component: () => import('../components/ms-capital/pages/companies/List'),
            },
            {
              name: 'ms-capital-companies-edit',
              path: 'ms-capital-companies-edit/:id?',
              component: () => import('../components/ms-capital/pages/companies/Form'),
              props: true,
            },
            {
              name: 'ms-capital-quotes-edit',
              path: 'ms-capital-quotes-edit/:id?',
              component: () => import('../components/ms-capital/pages/companies/QuoteForm'),
              props: true,
            },
            {
              name: 'ms-capital-contents-edit',
              path: 'ms-capital-contents-edit/:id?',
              component: () => import('../components/ms-capital/pages/companies/ContentForm'),
              props: true,
            },
            {
              name: 'ms-capital-stats-edit',
              path: 'ms-capital-stats-edit/:id?',
              component: () => import('../components/ms-capital/pages/companies/StatsForm'),
              props: true,
            },
            {
              name: 'ms-capital-num-list-edit',
              path: 'ms-capital-num-list-edit/:id?',
              component: () => import('../components/ms-capital/pages/companies/NumListForm'),
              props: true,
            },
            {
              name: 'ms-capital-page-ceo',
              path: 'ms-capital-page-ceo/:id?',
              component: () => import('../components/ms-capital/pages/CeoForm'),
            },
            {
              name: 'ms-capital-page-about',
              path: 'ms-capital-page-about',
              component: () => import('../components/ms-capital/pages/about/Form'),
            },
            {
              name: 'ms-capital-page-about-item',
              path: 'ms-capital-page-about-item/:id?',
              component: () => import('../components/ms-capital/pages/about/ItemForm'),
              props: true,
            },
            {
              name: 'ms-capital-page-development',
              path: 'ms-capital-page-development',
              component: () => import('../components/ms-capital/pages/development/Form'),
            },
            {
              name: 'ms-capital-page-contacts',
              path: 'ms-capital-page-contacts',
              component: () => import('../components/ms-capital/pages/Contacts'),
            },
          ],
        },
        {
          name: 'ms',
          path: 'ms',
          component: EmptyParentComponent,
          children: [
            {
              name: 'ms-menu',
              path: 'ms-menu',
              component: () => import('../components/ms/menu/MenuList'),
            },
            {
              name: 'ms-menu-edit',
              path: 'ms-menu-edit/:id?',
              component: () => import('../components/ms/menu/MenuForm'),
              props: true,
            },
            {
              name: 'ms-news',
              path: 'ms-news',
              component: () => import('../components/ms/news/List'),
            },
            {
              name: 'ms-news-edit',
              path: 'ms-news-edit/:id?',
              component: () => import('../components/ms/news/Form'),
              props: true,
            },
            {
              name: 'ms-widgets',
              path: 'ms-widgets',
              disabled: true,
              component: EmptyParentComponent,
            },
            {
              name: 'ms-widgets-value',
              path: 'ms-widgets-value',
              component: () => import('../components/ms/widgets/value/List'),
            },
            {
              name: 'ms-widgets-value-edit',
              path: 'ms-widgets-value-edit/:id?',
              component: () => import('../components/ms/widgets/value/Form'),
              props: true,
            },
            {
              name: 'ms-page-home',
              path: 'ms-page-home',
              component: () => import('../components/ms/pages/home/Form'),
            },
            {
              name: 'ms-home-edit',
              path: 'ms-home-edit/:id?',
              component: () => import('../components/ms-capital/pages/HomeForm'),
              props: true,
            },
            {
              name: 'ms-companies',
              path: 'ms-companies',
              component: () => import('../components/ms/pages/companies/Form'),
            },
            {
              name: 'ms-counter',
              path: 'ms-counter/:field/:id?',
              component: () => import('../components/ms/widgets/counter/Form'),
              props: true,
            },
            {
              name: 'ms-capital-companies-edit',
              path: 'ms-capital-companies-edit/:id?',
              component: () => import('../components/ms-capital/pages/companies/Form'),
              props: true,
            },
            {
              name: 'ms-quotes-edit',
              path: 'ms-quotes-edit/:id?',
              component: () => import('../components/ms/pages/companies/QuoteForm'),
              props: true,
            },
            {
              name: 'ms-contents-edit',
              path: 'ms-contents-edit/:id?',
              component: () => import('../components/ms/pages/companies/ContentForm'),
              props: true,
            },
            {
              name: 'ms-stats-edit',
              path: 'ms-stats-edit/:id?',
              component: () => import('../components/ms/pages/companies/StatsForm'),
              props: true,
            },
            {
              name: 'ms-num-list-edit',
              path: 'ms-num-list-edit/:id?',
              component: () => import('../components/ms/pages/companies/NumListForm'),
              props: true,
            },
            {
              name: 'ms-page-ceo',
              path: 'ms-page-ceo/:id?',
              component: () => import('../components/ms/pages/ceo/Form'),
            },
            {
              name: 'ms-page-about',
              path: 'ms-page-about',
              component: () => import('../components/ms/pages/about/Form'),
            },
            {
              name: 'ms-capital-page-about-item',
              path: 'ms-capital-page-about-item/:id?',
              component: () => import('../components/ms-capital/pages/about/ItemForm'),
              props: true,
            },
            {
              name: 'ms-page-development',
              path: 'ms-page-development',
              component: () => import('../components/ms/pages/development/Form'),
            },
            {
              name: 'ms-page-contacts',
              path: 'ms-page-contacts',
              component: () => import('../components/ms/pages/Contacts'),
            },
            {
              name: 'ms-forms',
              path: 'ms-forms',
              component: () => import('../components/ms/pages/Contacts'),
            },
          ],
        },
        {
          name: 'bgk',
          path: 'bgk',
          component: EmptyParentComponent,
          children: [
            {
              name: 'bgk-menu',
              path: 'bgk-menu',
              component: () => import('../components/bgk/menu/MenuList'),
            },
            {
              name: 'bgk-menu-edit',
              path: 'bgk-menu-edit/:id?',
              component: () => import('../components/bgk/menu/MenuForm'),
              props: true,
            },
            {
              name: 'bgk-widgets',
              path: 'bgk-widgets',
              disabled: true,
              component: EmptyParentComponent,
            },
            {
              name: 'bgk-widgets-stats',
              path: 'bgk-widgets-stats',
              component: () => import('../components/bgk/widgets/stats/List'),
            },
            {
              name: 'bgk-widgets-stats-edit',
              path: 'bgk-widgets-stats-edit/:id?',
              component: () => import('../components/bgk/widgets/stats/Form'),
              props: true,
            },
            {
              name: 'bgk-page-about',
              path: 'bgk-page-about',
              component: () => import('../components/bgk/pages/about/Form'),
            },
            {
              name: 'bgk-page-home',
              path: 'bgk-page-home',
              component: () => import('../components/bgk/pages/home/Form'),
            },
            {
              name: 'bgk-page-advantages',
              path: 'bgk-page-advantages',
              component: () => import('../components/bgk/pages/advantages/Form'),
            },
            {
              name: 'bgk-page-history',
              path: 'bgk-page-history',
              component: () => import('../components/bgk/pages/history/Form'),
            },
            {
              name: 'bgk-page-production',
              path: 'bgk-page-production',
              component: () => import('../components/bgk/pages/production/Form'),
            },
            {
              name: 'bgk-page-report',
              path: 'bgk-page-report',
              component: () => import('../components/bgk/pages/report/Form'),
            },
            {
              name: 'bgk-page-report-edit',
              path: 'bgk-page-report-edit/:id?',
              component: () => import('../components/bgk/pages/report/edit/Form'),
            },
            {
              name: 'bgk-page-contacts',
              path: 'bgk-page-contacts',
              component: () => import('../components/bgk/pages/contacts/Form'),
            },
            {
              name: 'bgk-page-items',
              path: 'bgk-page-items/:id?',
              component: () => import('../components/bgk/ItemForm'),
              props: true,
            },
          ],
        },
        {
          name: 'prylukyagro',
          path: 'prylukyagro',
          component: EmptyParentComponent,
          children: [
            {
              name: 'prylukyagro-menu',
              path: 'prylukyagro-menu',
              component: () => import('../components/prylukyagro/menu/MenuList'),
            },
            {
              name: 'prylukyagro-menu-edit',
              path: 'prylukyagro-menu/:id?',
              component: () => import('../components/prylukyagro/menu/MenuForm'),
              props: true,
            },
            {
              name: 'prylukyagro-pages',
              path: 'prylukyagro-pages',
              component: EmptyParentComponent,
            },
            {
              name: 'prylukyagro-pages-home',
              path: 'prylukyagro-pages-home',
              component: () => import('../components/prylukyagro/pages/Home'),
            },
            {
              name: 'prylukyagro-pages-about',
              path: 'prylukyagro-pages-about',
              component: () => import('../components/prylukyagro/pages/About'),
            },
            {
              name: 'prylukyagro-pages-technic',
              path: 'prylukyagro-pages-technic',
              component: () => import('../components/prylukyagro/pages/Technic'),
            },
            {
              name: 'prylukyagro-pages-service',
              path: 'prylukyagro-pages-service',
              component: () => import('../components/prylukyagro/pages/Service'),
            },
            {
              name: 'prylukyagro-pages-object',
              path: 'prylukyagro-pages-object',
              component: () => import('../components/prylukyagro/pages/Object'),
            },
            {
              name: 'prylukyagro-pages-benefits',
              path: 'prylukyagro-pages-benefits',
              component: () => import('../components/prylukyagro/pages/Benefits'),
            },
            {
              name: 'prylukyagro-pages-values',
              path: 'prylukyagro-pages-values',
              component: () => import('../components/prylukyagro/pages/Values'),
            },
            {
              name: 'prylukyagro-pages-career',
              path: 'prylukyagro-pages-career',
              component: () => import('../components/prylukyagro/pages/Career'),
            },
            {
              name: 'prylukyagro-pages-contacts',
              path: 'prylukyagro-pages-contacts',
              component: () => import('../components/prylukyagro/pages/Contact'),
            },
            {
              name: 'prylukyagro-pages-news',
              path: 'prylukyagro-pages-news',
              component: () => import('../components/prylukyagro/pages/News'),
            },
            {
              name: 'prylukyagro-pages-error',
              path: 'prylukyagro-pages-error',
              component: () => import('../components/prylukyagro/pages/Error'),
            },
            {
              name: 'prylukyagro-news',
              path: 'prylukyagro-news',
              component: () => import('../components/prylukyagro/news/List'),
            },
            {
              name: 'prylukyagro-news-edit',
              path: 'prylukyagro-news-edit/:id?',
              component: () => import('../components/prylukyagro/news/Form'),
              props: true,
            },
            {
              name: 'prylukyagro-widgets',
              path: 'prylukyagro-widgets',
              disabled: true,
              component: EmptyParentComponent,
            },
            {
              name: 'prylukyagro-widgets-slider',
              path: 'prylukyagro-widgets-slider',
              component: () => import('../components/prylukyagro/widgets/slider/List'),
            },
            {
              name: 'prylukyagro-slider-edit',
              path: 'prylukyagro-slider-edit/:id?',
              component: () => import('../components/prylukyagro/widgets/slider/Form'),
              props: true,
            },
            {
              name: 'prylukyagro-widgets-partner',
              path: 'prylukyagro-widgets-partner',
              component: () => import('../components/prylukyagro/widgets/partner/List'),
            },
            {
              name: 'prylukyagro-partner-edit',
              path: 'prylukyagro-partner-edit/:id?',
              component: () => import('../components/prylukyagro/widgets/partner/Form'),
              props: true,
            },
            {
              name: 'prylukyagro-widgets-service',
              path: 'prylukyagro-widgets-service',
              component: () => import('../components/prylukyagro/widgets/service/List'),
            },
            {
              name: 'prylukyagro-service-edit',
              path: 'prylukyagro-service-edit/:id?',
              component: () => import('../components/prylukyagro/widgets/service/Form'),
              props: true,
            },
            {
              name: 'prylukyagro-widgets-contacts',
              path: 'prylukyagro-widgets-contacts',
              component: () => import('../components/prylukyagro/widgets/contacts/Form'),
            },
            {
              name: 'prylukyagro-widgets-plants',
              path: 'prylukyagro-widgets-plants',
              component: () => import('../components/prylukyagro/widgets/plants/List'),
            },
            {
              name: 'prylukyagro-contacts-edit',
              path: 'prylukyagro-contacts-edit/:id?',
              component: () => import('../components/prylukyagro/widgets/contacts/ContactsEdit'),
            },
            {
              name: 'prylukyagro-plants-edit',
              path: 'prylukyagro-plants-edit/:id?',
              component: () => import('../components/prylukyagro/widgets/plants/Form'),
              props: true,
            },
            {
              name: 'prylukyagro-widgets-achievements',
              path: 'prylukyagro-widgets-achievements',
              component: () => import('../components/prylukyagro/widgets/achievement/List'),
            },
            {
              name: 'prylukyagro-achievements-edit',
              path: 'prylukyagro-achievements-edit/:id?',
              component: () => import('../components/prylukyagro/widgets/achievement/Form'),
              props: true,
            },
            {
              name: 'prylukyagro-widgets-geography',
              path: 'prylukyagro-geography',
              component: () => import('../components/prylukyagro/widgets/geography/Form'),
            },
            {
              name: 'prylukyagro-widgets-team',
              path: 'prylukyagro-widgets-team',
              component: () => import('../components/prylukyagro/widgets/team/List'),
            },
            {
              name: 'prylukyagro-team-edit',
              path: 'prylukyagro-team-edit/:id?',
              component: () => import('../components/prylukyagro/widgets/team/Form'),
              props: true,
            },
            {
              name: 'prylukyagro-widgets-social',
              path: 'prylukyagro-widgets-social',
              component: () => import('../components/prylukyagro/widgets/social/List'),
            },
            {
              name: 'prylukyagro-social-edit',
              path: 'prylukyagro-social-edit/:id?',
              component: () => import('../components/prylukyagro/widgets/social/Form'),
              props: true,
            },
            {
              name: 'prylukyagro-widgets-departments',
              path: 'prylukyagro-widgets-departments',
              component: () => import('../components/prylukyagro/widgets/department/List'),
            },
            {
              name: 'prylukyagro-departments-edit',
              path: 'prylukyagro-departments-edit/:id?',
              component: () => import('../components/prylukyagro/widgets/department/Form'),
              props: true,
            },
            {
              name: 'prylukyagro-widgets-vacancy',
              path: 'prylukyagro-widgets-vacancy',
              component: () => import('../components/prylukyagro/widgets/vacancy/List'),
            },
            {
              name: 'prylukyagro-vacancy-edit',
              path: 'prylukyagro-vacancy-edit/:id?',
              component: () => import('../components/prylukyagro/widgets/vacancy/Form'),
              props: true,
            },
            {
              name: 'prylukyagro-widgets-statistic',
              path: 'prylukyagro-widgets-statistic',
              component: () => import('../components/prylukyagro/widgets/statistic/List'),
            },
            {
              name: 'prylukyagro-statistic-edit',
              path: 'prylukyagro-statistic-edit/:id?',
              component: () => import('../components/prylukyagro/widgets/statistic/Form'),
              props: true,
            },
            {
              name: 'prylukyagro-widgets-benefits',
              path: 'prylukyagro-widgets-benefits',
              component: () => import('../components/prylukyagro/widgets/benefits/List'),
            },
            {
              name: 'prylukyagro-benefits-edit',
              path: 'prylukyagro-benefits-edit/:id?',
              component: () => import('../components/prylukyagro/widgets/benefits/Form'),
              props: true,
            },
            {
              name: 'prylukyagro-widgets-values',
              path: 'prylukyagro-widgets-values',
              component: () => import('../components/prylukyagro/widgets/values/List'),
            },
            {
              name: 'prylukyagro-values-edit',
              path: 'prylukyagro-values-edit/:id?',
              component: () => import('../components/prylukyagro/widgets/values/Form'),
              props: true,
            },
            {
              name: 'prylukyagro-widgets-advantage',
              path: 'prylukyagro-widgets-advantage',
              component: () => import('../components/prylukyagro/widgets/advantage/List'),
            },
            {
              name: 'prylukyagro-advantage-edit',
              path: 'prylukyagro-advantage-edit/:id?',
              component: () => import('../components/prylukyagro/widgets/advantage/Form'),
              props: true,
            },
            {
              name: 'prylukyagro-widgets-machinery',
              path: 'prylukyagro-widgets-machinery',
              component: () => import('../components/prylukyagro/widgets/machinery/List'),
            },
            {
              name: 'prylukyagro-machinery-edit',
              path: 'prylukyagro-machinery-edit/:id?',
              component: () => import('../components/prylukyagro/widgets/machinery/Form'),
              props: true,
            },
            {
              name: 'prylukyagro-widgets-region',
              path: 'prylukyagro-widgets-region',
              component: () => import('../components/prylukyagro/widgets/region/List'),
            },
            {
              name: 'prylukyagro-region-edit',
              path: 'prylukyagro-region-edit/:id?',
              component: () => import('../components/prylukyagro/widgets/region/Form'),
              props: true,
            },
            {
              name: 'prylukyagro-widgets-object-type',
              path: 'prylukyagro-widgets-object-type',
              component: () => import('../components/prylukyagro/widgets/object-type/List'),
            },
            {
              name: 'prylukyagro-object-type-edit',
              path: 'prylukyagro-object-type-edit/:id?',
              component: () => import('../components/prylukyagro/widgets/object-type/Form'),
              props: true,
            },
            {
              name: 'prylukyagro-widgets-object-class',
              path: 'prylukyagro-widgets-object-class',
              component: () => import('../components/prylukyagro/widgets/object-class/List'),
            },
            {
              name: 'prylukyagro-object-class-edit',
              path: 'prylukyagro-object-class-edit/:id?',
              component: () => import('../components/prylukyagro/widgets/object-class/Form'),
              props: true,
            },
            {
              name: 'prylukyagro-widgets-objects',
              path: 'prylukyagro-widgets-objects',
              component: () => import('../components/prylukyagro/widgets/objects/List'),
            },
            {
              name: 'prylukyagro-objects-edit',
              path: 'prylukyagro-objects-edit/:id?',
              component: () => import('../components/prylukyagro/widgets/objects/Form'),
              props: true,
            },
            {
              name: 'prylukyagro-widgets-aw',
              path: 'prylukyagro-widgets-aw',
              component: () => import('../components/prylukyagro/widgets/aw/List'),
            },
            {
              name: 'prylukyagro-aw-edit',
              path: 'prylukyagro-aw-edit/:id?',
              component: () => import('../components/prylukyagro/widgets/aw/Form'),
              props: true,
            },
            {
              name: 'prylukyagro-widgets-quality',
              path: 'prylukyagro-widgets-quality',
              component: () => import('../components/prylukyagro/widgets/quality/List'),
            },
            {
              name: 'prylukyagro-quality-edit',
              path: 'prylukyagro-quality-edit/:id?',
              component: () => import('../components/prylukyagro/widgets/quality/Form'),
              props: true,
            },
            {
              name: 'prylukyagro-forms',
              path: 'prylukyagro-forms',
              // disabled: true,
              // component: EmptyParentComponent,
              component: () => import('../components/prylukyagro/forms/List'),
            },
            // {
            //   name: 'prylukyagro-forms-feedback',
            //   path: 'prylukyagro-forms-feedback',
            //   component: () => import('../components/prylukyagro/forms/Feedback'),
            // },
            // {
            //   name: 'prylukyagro-forms-career',
            //   path: 'prylukyagro-forms-career',
            //   component: () => import('../components/prylukyagro/forms/Career'),
            // },
            {
              name: 'prylukyagro-request',
              path: 'prylukyagro-request',
              component: () => import('../components/prylukyagro/request/List'),
            },
            {
              name: 'prylukyagro-request-view',
              path: 'prylukyagro-request/:id',
              component: () => import('../components/prylukyagro/request/Item'),
              props: true,
            },
            // {
            //   name: 'prylukyagro-settings',
            //   path: 'prylukyagro-settings',
            //   disabled: true,
            //   component: EmptyParentComponent,
            // },
            // {
            //   name: 'prylukyagro-settings-mailer',
            //   path: 'prylukyagro-settings-mailer',
            //   component: () => import('../components/prylukyagro/settings/mailer/List'),
            // },
            // {
            //   name: 'prylukyagro-settings-mailer-edit',
            //   path: 'prylukyagro-settings-mailer-edit/:id?',
            //   component: () => import('../components/prylukyagro/settings/mailer/Form'),
            //   props: true,
            // },
            // {
            //   name: 'prylukyagro-settings-config',
            //   path: 'prylukyagro-settings-config',
            //   component: () => import('../components/prylukyagro/settings/Config'),
            // },
            {
              name: 'prylukyagro-settings',
              path: 'prylukyagro-settings',
              component: () => import('../components/prylukyagro/settings/Settings'),
            },
            // {
            //   name: 'prylukyagro-settings-translations',
            //   path: 'prylukyagro-settings-translations',
            //   component: () => import('../components/prylukyagro/settings/translations/List'),
            // },
            // {
            //   name: 'prylukyagro-settings-translations-edit',
            //   path: 'prylukyagro-settings-translations-edit/:id?',
            //   component: () => import('../components/prylukyagro/settings/translations/Form'),
            //   props: true,
            // },
            // {
            //   name: 'prylukyagro-settings-languages',
            //   path: 'prylukyagro-settings-languages',
            //   component: () => import('../components/prylukyagro/settings/languages/List'),
            // },
            // {
            //   name: 'prylukyagro-settings-languages-edit',
            //   path: 'prylukyagro-settings-languages-edit/:id?',
            //   component: () => import('../components/prylukyagro/settings/languages/Form'),
            //   props: true,
            // },
            {
              name: 'prylukyagro-seo',
              path: 'prylukyagro-seo',
              component: () => import('../components/prylukyagro/Seo'),
              props: true,
            },
            {
              name: 'prylukyagro-letters',
              path: 'prylukyagro-letters',
              component: () => import('../components/prylukyagro/letters/List'),
            },
            {
              name: 'prylukyagro-letters-edit',
              path: 'prylukyagro-letters-edit/:id',
              component: () => import('../components/prylukyagro/letters/Item'),
              props: true,
            },
          ],
        },
      ],
    },
  ],
})
