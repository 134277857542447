// Polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import { ColorThemePlugin } from '../services/vuestic-ui'
import store from '../store/index'
import router from '../router/index'
import { VuesticPlugin } from '../services/vuestic-ui/components'
import '../i18n/index'
import YmapPlugin from 'vue-yandex-maps'
import VueClipboard from 'vue-clipboard2'

import '../metrics'
import '../registerServiceWorker'

import { consoleBuildInfo } from 'vue-cli-plugin-build-info/plugin'
// token management
import Axios from 'axios'
Vue.prototype.$http = Axios
Vue.prototype.$http.interceptors.response.use(response => {
  // console.log(response)
  return response
}, error => {
  // console.log(error)
  if (error.response.status === 401) {
    if (router.currentRoute.name !== 'login') {
      router.push({ name: 'login' })
    }
  }
  return Promise.reject(error)
})
Vue.prototype.$http.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')

    if (token) {
      config.headers.Authorization = token
    }

    return config
  },
  (error) => {
    return error
  },
)

consoleBuildInfo()

Vue.use(VuesticPlugin)
Vue.use(YmapPlugin)
Vue.use(VueClipboard)

Vue.use(ColorThemePlugin, {
  primary: '#6C7FEE',
  secondary: '#6E7FF1',
  success: '#8DDC88',
  info: '#71BAFF',
  danger: '#F8706D',
  warning: '#FFD652',
  gray: '#8396A5',
  dark: '#34495E',
})

router.beforeEach((to, from, next) => {
  store.commit('setLoading', true)
  if (to.name !== 'login') {
    // auth check
    Axios.get(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/uk/admin/menu`)
      .then(() => {
        next()
      })
      .catch(error => {
        if (error.response.status === 401) {
          next('login')
        }
      })
  } else {
    next()
  }
})

router.afterEach((to, from) => {
  store.commit('setLoading', false)
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
})
